import { WorkFlow } from '../../../pages/WorkFlow/WorkFlow'
import { AddWorkFlow } from '../../../pages/WorkFlow/Create/create'
import { getUserPermissions } from '../../../utils/localStorage'
import { IRoute } from './PermissionRoutes'

const permissions = getUserPermissions()

export const workflowRoutes: IRoute[] = [
    {
        path: 'workflow',
        comp: <WorkFlow />,
        isAllowed: permissions.has('workflow_approval'),
    },
    {
        path: 'workflow/add',
        comp: <AddWorkFlow />,
        isAllowed: permissions.has('workflow_approval'),
    },
    // {
    //     path: 'workflow/edit',
    //     comp: <EditWorkFlow />,
    //     isAllowed:permissions.has('workflow_approval_edit'),
    // },
]
