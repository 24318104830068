interface InputData {
    placeholder: string
    value?: string
    handleChange?: any
    loading: boolean
    name?: string
    register?: any
    type?: any
    pattern?: any
    readOnly?: boolean
    disabled?: boolean
    min?: number
    className?: any
    handleBlur?: any
}

export function Input({
    placeholder,
    value,
    handleChange,
    handleBlur,
    loading,
    name,
    register,
    type = 'text',
    pattern,
    readOnly,
    disabled,
    min,
    className,
}: InputData): JSX.Element {
    return (
        <input
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            disabled={loading || disabled}
            className={`border border-[#D7DCE0] h-[44px] w-full rounded-md px-2 py-1.5 text-[#3A434B] text-[13px] placeholder:text-[13px] ${className}`}
            name={name}
            {...register}
            type={type}
            pattern={pattern}
            readOnly={readOnly}
            onBlur={handleBlur}
            min={min}
        />
    )
}
