import { Suspense, lazy } from 'react'

import { OrganizationProvider } from '../../../../pages/Settings/Organization/context/orgContext'
const AddOrganization = lazy(
    () => import('../../../../pages/Settings/Organization/New/Create/Create')
)

const OrganizationDetails = lazy(
    () => import('../../../../pages/Settings/Organization/details')
)

const EditOrganization = lazy(
    () => import('../../../../pages/Settings/Organization/Update/Edit/Edit')
)

const OrganizationMangement = lazy(
    () => import('../../../../pages/Settings/Organization/Organization')
)

const OrganizatioPreview = lazy(
    () => import('../../../../pages/Settings/Organization/New/Preview')
)

const OrganizationEditPreview = lazy(
    () => import('../../../../pages/Settings/Organization/Update/Preview')
)

import { IRoute } from '../PermissionRoutes'
import { getUserPermissions } from '../../../../utils/localStorage'
import { LandingBg } from '../../../LandingBg/LandingBg'

const permissions = getUserPermissions()

export const organizationRoutes: IRoute[] = [
    {
        path: 'settings',
        comp: (
            <OrganizationProvider>
                <OrganizationMangement />
            </OrganizationProvider>
        ),
        isAllowed: permissions.has('settings'),
    },
    {
        path: 'settings/organization',
        comp: (
            <OrganizationProvider>
                <OrganizationMangement />
            </OrganizationProvider>
        ),
        isAllowed: permissions.has('settings_organization'),
    },
    {
        path: 'settings/organization/edit',
        comp: (
            <OrganizationProvider>
                <EditOrganization />
            </OrganizationProvider>
        ),
        isAllowed: permissions.has('settings_organization'),
    },
    {
        path: 'settings/organization/edit-preview',
        comp: (
            <OrganizationProvider>
                <OrganizationEditPreview />
            </OrganizationProvider>
        ),
        isAllowed: permissions.has('settings_organization'),
    },
    {
        path: 'settings/organization/preview',
        comp: (
            <OrganizationProvider>
                <OrganizatioPreview />
            </OrganizationProvider>
        ),
        isAllowed: permissions.has('settings_organization'),
    },
    {
        path: 'settings/organization/add',
        comp: (
            <OrganizationProvider>
                <AddOrganization />
            </OrganizationProvider>
        ),
        isAllowed: permissions.has('settings_organization'),
    },
    {
        path: 'settings/organization/detail/:id',
        comp: (
            <OrganizationProvider>
                <OrganizationDetails />
            </OrganizationProvider>
        ),
        isAllowed: permissions.has('settings_organization'),
    },
]
