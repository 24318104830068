import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchOrganizations } from '../utils/services/settings/organization'

export default function useOrganizations() {
    const [organizations, setOxrganizations] = useState([])

    const payload = {
        pageSize: 100,
        from: 0,
        parameter: {},
    }

    const { refetch } = useQuery(
        ['GetAllorganizations'],
        () => fetchOrganizations(payload),
        {
            enabled: true,
            retry: 2,
            onSuccess: (response) => {
                setOxrganizations(response.data.items)
            },
        }
    )

    const getOrganizations = () => {
        refetch()
    }

    return { organizations, getOrganizations }
}
