import { Suspense, lazy } from 'react'
const ContractServicePage = lazy(
    () => import('../../../../pages/Settings/ContractService/ContractService')
)

const CreateContract = lazy(
    () => import('../../../../pages/Settings/ContractService/Create/Create')
)

import { getUserPermissions } from '../../../../utils/localStorage'
import { LandingBg } from '../../../LandingBg/LandingBg'
import { IRoute } from '../PermissionRoutes'

const permissions = getUserPermissions()

export const contractServiceRoutes: IRoute[] = [
    {
        path: 'settings/contract-service',
        comp: <ContractServicePage />,
        isAllowed: permissions.has('contract_services'),
    },
    {
        path: 'settings/contract-service/add',
        comp: <CreateContract />,
        isAllowed: permissions.has('contract_services'),
    },
]
