import React from 'react'
import { Spinner } from '../Spinner/Spinner'

interface IOutineButton {
  label: string
  handleClick?: any
  type?: any
  isLoading?: boolean
  disabled? : boolean
  color? : string
  
}

export function ButtonPrimary({ label, handleClick, type, isLoading, color, disabled }: IOutineButton): JSX.Element {
  return (
    <button
      className={`flex justify-center items-center ${disabled? 'bg-opacity-60': ''}  border rounded-sm h-10 w-24 md:w-36 text-xs font-Inter font-semibold capitalize  ${color? color : 'border-[#219653] bg-[#219653]  text-white'}`}
      onClick={handleClick}
      type={type}
      disabled={isLoading || disabled}
    >
      {isLoading ? (<Spinner />) : label}
    </button>
    );
}
