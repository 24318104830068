import React, { createContext, useState } from 'react'

export const defaultValue = {
    operationInfo: {
        operationType: {
            value: '',
            label: '',
        },
        operationDate: '',
        airline: {
            value: '',
            label: '',
        },
        aircraftCategory: {
            value: '',
            label: '',
        },
        handlingType: {
            value: '',
            label: '',
        },
    },
    selectFlight: {},
    contractId: 0,
    serviceRendered: {},
    selectedContractService: {
        activeContracts: [],
        code: '',
        description: 'hope',
        icon: '',
        id: 0,
        name: '',
    },
    entryType: {
        name: 'UnScheduled',
        id: 1
    },
    equipByOrgId: []
}

interface IContext {
    operationInfo: IOperationInfo
    selectFlight: any
    serviceRendered: any
    equipByOrgId: any
    selectedContractService: ISelectedContractService
    contractId: number
    entryType: IEntryType
}
interface IData {
    allDataUns: IContext
    setAllDataUns: Function
}
interface IOperationInfo {
    operationType: {
        value: string
        label: string
    }
    operationDate: string
    airline: {
        value: string
        label: string
    }
    aircraftCategory: {
        value: string
        label: string
    }
    handlingType: {
        value: string
        label: string
    }
    id?: number
}
interface IEntryType {
    name: string
    id: number
}
interface ISelectedContractService {
    activeContracts: any[]
    code: string
    description: string
    icon: string
    id: number
    name: string
}

export const UnscheduledContext = createContext<IData>({
    allDataUns: defaultValue,
    setAllDataUns: () => {},
})

export function UnscheduledProvider({ children }: any) {
    const [allDataUns, setAllDataUns] = useState(defaultValue)
    return (
        <UnscheduledContext.Provider value={{ allDataUns, setAllDataUns }}>
            {children}
        </UnscheduledContext.Provider>
    )
}
