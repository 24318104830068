import { ReactElement } from 'react'
import { Outlet, useNavigate, Navigate, useLocation } from 'react-router-dom'
import AccessDenied from '../../assets/img/access-denied.svg'
import { isAuthenticated } from '../../utils/isAuthenticated'
import { getSavedState } from '../../utils/localStorage'
import { ButtonPrimary } from '../UIKit/ButtonPrimary/Button'
type RouteProps = {
    comp: ReactElement
    isAllowed?: boolean
}

export const Access = ({ isAllowed, comp }: RouteProps) => {
    const CCPUser = getSavedState('CCPUser')
    const {userName} = CCPUser
    const location = useLocation()

    const navigate = useNavigate();
    if (isAllowed) {
        return comp ? comp : <Outlet />
    }
    if (!isAuthenticated()) {
        return <Navigate to="/" state={{ path: location.pathname, deniedUser: userName }} />
    }
    return (
        <div className="w-full h-screen grid grid-cols-1 lg:grid-cols-2 bg-[#E8ECF5]">
            <div className="flex flex-col items-center justify-center">
                <h1 className="text-white text-9xl">403</h1>
                <h6 className=" font-bold my-2">Access Denied</h6>
                <p className="mt-2 mb-5 text-slate-400 text-center">
                    You don't have permission to access requested page
                </p>
                <ButtonPrimary
                    label="Go Back"
                    handleClick={() => navigate(-1)}
                />
            </div>
            <div className="hidden lg:flex lg:flex-col items-center justify-center">
                <img
                    src={AccessDenied}
                    className="w-[70%]"
                    alt="access denied"
                />
            </div>
        </div>
    )
}
