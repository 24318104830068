import ImgNotFound from '../../assets/img/not-found.svg'
import LandingLayout from '../../components/LandingLayout/LandingLayout'

export const NotFound = () => {
    return (
        <LandingLayout>
            <div className="mb-3">
                The content your are looking for does not exist
            </div>
            <img src={ImgNotFound} alt="not fouund" />
        </LandingLayout>
    )
}
