import { useState } from 'react'
import { useQuery } from 'react-query'
import { getContractServices } from '../utils/services/contract'

export default function useContractService() {
    const [contractService, setContractService] = useState<any>([])

    const { refetch } = useQuery(['GetContractServices'], () => getContractServices(), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setContractService(response.data)
        },
    })

    const fetchContractService = () => {
        refetch();
    }

    return { contractService, fetchContractService }
}
