import { ReactElement } from 'react'

type IDropdown = {
    isOpen: boolean
    setIsOpen: any
    children?: any
}

export function Dropdown({
    isOpen,
    setIsOpen,
    children,
}: IDropdown): ReactElement {
    return (
        <div
            className={
                ' fixed overflow-hidden z-40 bg-gray-500/5 bg-opacity-25 inset-0 transform ease-in-out ' +
                (isOpen
                    ? ' transition-opacity opacity-100 duration-500 translate-x-0  '
                    : ' transition-all opacity-0 translate-x-full  ')
            }
        >
            <div
                className="w-screen h-full cursor-pointer"
                onClick={() => setIsOpen(false)}
            >
                {children}
            </div>
        </div>
    )
}
