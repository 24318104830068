import logo from '../../assets/img/CCP.png'
export const LandingBg = () => {
    return (
        <div className="loading-scr">
            <div className="loading-animation">
                <img src={logo} alt="ccp" />
                <div className="load-bar"></div>
            </div>
        </div>
    )
}
