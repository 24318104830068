import { FC } from 'react'

export const BackArrow: FC<{ className: string }> = ({ className }) => {
    return (
        <svg
            width="26"
            height="24"
            viewBox="0 0 33 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.125 13.5001H31.875M13.0833 25.4584L1.125 13.5001L13.0833 25.4584ZM1.125 13.5001L13.0833 1.54175L1.125 13.5001Z"
                stroke={className}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
