import { Suspense, lazy } from 'react'
const Payment = lazy(() => import('../../../pages/Settlements/Payment/Payment'))

const Reconcilliation = lazy(
    () => import('../../../pages/Settlements/Reconcilliation/Reconcilliation')
)
const Refund = lazy(() => import('../../../pages/Settlements/Refund/Refund'))
const Statement = lazy(
    () => import('../../../pages/Settlements/Statement/Statement')
)
import { getUserPermissions } from '../../../utils/localStorage'
import { LandingBg } from '../../LandingBg/LandingBg'
import { IRoute } from './PermissionRoutes'
const permissions = getUserPermissions()

export const settlementRoutes: IRoute[] = [
    {
        path: 'settlements',
        comp: <Payment />,
        isAllowed: permissions.has('settlement'),
    },
    {
        path: 'settlements/payments',
        comp: <Payment />,
        isAllowed: permissions.has('settlement'),
    },
    {
        path: 'settlements/reconcilation',
        comp: <Reconcilliation />,
        isAllowed: permissions.has('settlement'),
    },
    {
        path: 'settlements/reconcilation/:id',
        comp: <Statement />,
        isAllowed: permissions.has('settlement'),
    },
    {
        path: 'settlements/refunds',
        comp: <Refund />,
        isAllowed: permissions.has('settlement'),
    },
]
