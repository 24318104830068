import { FC } from 'react'
import NoData from '../../../assets/img/DataCapture/no-data.svg'
import EmptyWidget from '../../../assets/img/Dashboard/empty-widget.svg'
export const EmptyState: FC<{
    message?: string
    variant?: 'widget' | 'fullscreen'
}> = ({ message = 'No Information Available', variant = 'fullscreen' }) => {
    if (variant === 'widget') {
        return (
            <div className="flex flex-col gap-5 items-center justify-center px-6  h-full ">
                <img src={EmptyWidget} className="" alt="no data" />
                <div className="font-medium text-xs text-gray-400">
                    {message}
                </div>
            </div>
        )
    }
    return (
        <div className="flex flex-col gap-5 items-center justify-center px-6 py-12 h-[60vh] border-[#F1F2F3] border ">
            <img src={NoData} alt="no data" />
            <div className="font-medium text-gray-400">{message}</div>
        </div>
    )
}
