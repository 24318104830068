import React, { createContext, useState } from 'react'

const defaultValue = {
    contactProfile: {
        industry: {
            value: '',
            label: '',
        },
        organizationName: '',
        shortName: '',
        hqrAddress: '',
        regNumber: '',
        homeCountry: {
            value: '',
            label: '',
        },
        email: '',
        phoneNumber: '',
        icaoCode: '',
        id: 0,
        isProvisional: {
            value: false,
            label: 'No',
        },
    },
    operationProfile: [
        {
            country: {
                value: '',
                label: '',
            },
            state: {
                value: '',
                label: '',
            },
            location: {
                value: '',
                label: '',
            },
            id: 0,
        },
    ],
    financeProfile: [
        {
            currencyType: {
                value: '',
                label: '',
            },
            bankName: {
                value: '',
                label: '',
            },
            accountName: '',
            accountNumber: '',
            id: 0,
        },
    ],
    assetProfile: [
        {
            assetCategory: {
                value: '',
                label: '',
            },
            assetType: {
                value: '',
                label: '',
            },
            assetID: '',
            assetName: '',
            id: 0,
        },
    ],
    formStep: 0,
}

interface IContext {
    contactProfile: IcontactProfile
    operationProfile: Array<IoperationProfile>
    financeProfile: Array<IFinanceProfile>
    assetProfile: Array<IAssetProfile>
    formStep: number
}
interface IData {
    allData: IContext
    setAllData: Function
}
interface IcontactProfile {
    industry: {
        value: string
        label: string
    }
    organizationName: string
    shortName: string
    hqrAddress: string
    regNumber: string
    homeCountry: {
        value: string
        label: string
    }
    email: string
    phoneNumber: string
    icaoCode?: string
    id?: number
    isProvisional: {
        value: boolean
        label: string
    }
}
interface IoperationProfile {
    country: {
        value: string
        label: string
    }
    state: {
        value: string
        label: string
    }
    location: {
        value: string
        label: string
    }
    id?: number
}
interface IFinanceProfile {
    currencyType: {
        value: string
        label: string
    }
    bankName: {
        value: string
        label: string
    }
    accountName: string
    accountNumber: string
    id?: number
}
interface IAssetProfile {
    assetCategory: {
        value: string
        label: string
    }
    assetType: {
        value: string
        label: string
    }
    assetID: string
    assetName: string
    id?: number
}

export const OrgContext = createContext<IData>({
    allData: defaultValue,
    setAllData: () => {},
})

export function OrganizationProvider({ children }: any) {
    const [allData, setAllData] = useState(defaultValue)
    return (
        <OrgContext.Provider value={{ allData, setAllData }}>
            {children}
        </OrgContext.Provider>
    )
}
