import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchRoles } from '../utils/services/settings/roles'

export default function useRoles() {
    const [roles, setRoles] = useState([])

    const { refetch } = useQuery(['GetAllRoles'], () => fetchRoles(), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setRoles(response.data)
        },
    })

    const getAllRoles = () => {
        refetch();
    }

    return { roles, getAllRoles }
}
