import React, { createContext, useState } from 'react'

export const defaultValue = {
    operationInfo: {
        forwardingAriline: {
            value: '',
            label: '',
        },
        receivingFlightNumber: {
            value: '',
            label: '',
        },
        forwardingFlightNumber: {
            value: '',
            label: '',
        },
        destinationAirport: {
            value: '',
            label: '',
        },
        departureAirport: {
            value: '',
            label: '',
        },
    },
    inviteeDetails: {
        emailAddress: '',
        phoneNumber: '',
        fullName: '',
        location: {
            value: '',
            label: '',
        },
    },   
}

interface IContext {
    operationInfo: IOperationInfo
    inviteeDetails: IInviteeDetails
}

interface IOperationInfo {
    forwardingAriline: {
        value: string,
        label: string,
    },
    receivingFlightNumber: {
        value: string,
        label: string,
    },
    forwardingFlightNumber: {
        value: string,
        label: string,
    },
    destinationAirport: {
        value: string,
        label: string,
    },
    departureAirport: {
        value: string,
        label: string,
    },
    id?: number
}
interface IInviteeDetails {
    emailAddress: string,
    phoneNumber: string,
    fullName: string,
    location: {
        value: string
        label: string
    },
    id?: number
}



interface IData {
    allDataADHOC: IContext
    setAllDataADHOC: React.Dispatch<React.SetStateAction<any>>
    resetAll: Function
}

export const AdhocContext = createContext<IData>({
    allDataADHOC: defaultValue,
    setAllDataADHOC: () => {},
    resetAll: async () => {},

})

export function AdhocProvider({ children }: any) {
    const [allDataADHOC, setAllDataADHOC] = useState(defaultValue)

    const resetAll = () => {
        setAllDataADHOC(defaultValue)
    }

    return (
        <AdhocContext.Provider value={{ allDataADHOC, setAllDataADHOC, resetAll}}>
            {children}
        </AdhocContext.Provider>
    )
}
