import { ReactElement, lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// const NewAdhoc = lazy(() => import('../../pages/DataCapture'))

const Login = lazy(() => import('../../pages/Login/Login'))
const ActivateAccount = lazy(
    () => import('../../pages/Login/ActivateAccount/ActivateAccount')
)
const InitiatePasswordReset = lazy(
    () =>
        import('../../pages/Login/InitiatePasswordReset/InitiatePasswordReset')
)
const ResetPassword = lazy(
    () => import('../../pages/Login/ResetPassword/ResetPassword')
)

const OnBoardingNewAdhoc = lazy(
    () => import('../../pages/DataCapture/OnboardingAdhoc')
)

const DemoPage = lazy(
    () => import('../../pages/DataCapture/demo')
)
const Playground = lazy(() => import('../../pages/Playground/Playground'))
import { NotFound } from '../../pages/NotFound/NotFound'
import { UiProvider } from '../../context/uiContext'
import { ToastContainer } from 'react-toastify'
import PermissionRoutes from './Routes/PermissionRoutes'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LandingBg } from '../../components/LandingBg/LandingBg'

function App(): ReactElement {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <UiProvider>
                <BrowserRouter>
                    <Suspense fallback={<LandingBg />}>
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route
                                path="/onboarding-new-adhoc/:email/:token/:destination/:producer"
                                element={<OnBoardingNewAdhoc />}
                            />
                            <Route
                                path="/initiate-password-reset"
                                element={<InitiatePasswordReset />}
                            />
                            <Route
                                path="/resetpassword"
                                element={<ResetPassword />}
                            />
                            <Route
                                path="/activate"
                                element={<ActivateAccount />}
                            />
                            
                            {/* <Route
                                path="/onboarding-new-adhoc/:email/:token/:destination/:producer"
                                element={<OnBoardingNewAdhoc />}
                            /> */}
                            <Route
                                path="/demo"
                                element={<DemoPage />}
                            />
                            {/* <Route path="/overview" element={<Overview />} /> */}
                            {/* <Route path="/contract" element={<ContractGroup />} /> */}
                            <Route path="/*" element={<PermissionRoutes />} />

                            <Route path="playground" element={<Playground />} />
                            <Route path="*" element={<NotFound />} />
                            {/* <Redirect to="/not-found" /> */}
                        </Routes>
                    </Suspense>
                </BrowserRouter>
                <ToastContainer className="p-3 w-fit text-sm" />
            </UiProvider>
        </LocalizationProvider>
    )
}
export default App
