import React, { ReactElement, useState, useContext, ReactNode } from 'react'
import { UiContext } from '../../../../context/uiContext'
import { useNavigate } from 'react-router-dom'
import arrowDown from '../../../../assets/img/arrowDown.svg'

type IDropDown = {
    label: string
    children?: ReactNode
    configureSubMenu?: ReactNode
    textColor?: string
    options?: { name: string; url?: string; canAccess?: boolean }[]
    onClick: () => void
    disabled?: boolean
}

export function Dropdown({
    label,
    options,
    textColor,
    children,
    configureSubMenu,
    onClick,
    disabled,
}: IDropDown): ReactElement {
    const navigate = useNavigate()
    const { showDropDown, setShowDropDown, setToggleSidebar } =
        useContext(UiContext)
    const handleClick = (path: string) => {
        navigate(`${path}`, { replace: true })
        setShowDropDown(null)
        setToggleSidebar(false)
    }
    return (
        <div className="w-full">
            <div className="relative text-left w-full flex">
                <div
                    className={`flex justify-between items-center w-full text-sm ${
                        textColor && textColor
                    }`}
                >
                    {children ? children : label}

                    <div
                        onClick={onClick}
                        className={`${!disabled ? 'cursor-pointer p-2 ' : ''}`}
                    >
                        <img src={arrowDown} alt="arrow down" />
                    </div>
                </div>

                {showDropDown === label && !disabled && (
                    <>
                        {configureSubMenu ? (
                            configureSubMenu
                        ) : (
                            <div className="origin-top-right absolute -right-5 mt-2 w-48 z-10 rounded-md shadow-lg bg-white opacity-100 focus:outline-none">
                                <div className="opacity-100" role="none">
                                    {options?.map((item, idx) => (
                                        <div
                                            key={idx}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                handleClick(item.url!)
                                            }}
                                            className={`${
                                                item.canAccess
                                                    ? 'block'
                                                    : 'hidden'
                                            } opacity-100 px-4 py-2 text-sm hover:bg-slate-300`}
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
