import { useState, useContext, FC } from 'react'
import { UiContext } from '../../../context/uiContext'
import { Link, useLocation } from 'react-router-dom'
import avatar from '../../../assets/img/navHeader/avatar.png'
import question from '../../../assets/img/question.svg'
import notification from '../../../assets/img/notification.svg'
import hamburger from '../../../assets/img/hamburger.svg'
import arrowDown from '../../../assets/img/arrowDown.svg'
import { ProfileDropdown } from '../../UIKit/ProfileDropdown'
interface Props {
    breadcrumbs?: any[]
}
export const AdminHeader: FC<Props> = ({ breadcrumbs }) => {
    const storageData: any = localStorage.getItem('CCPUser')
    const currentUser = JSON.parse(storageData)
    const ddName = currentUser?.fullName && currentUser?.fullName.split(' ')
    const curUserName = ddName && `${ddName[0]} ${ddName[1].substr(0, 1)}`
    const location = useLocation()
    const { toggleSidebar, setToggleSidebar } = useContext(UiContext)
    const [searchPhrase, setSearchPhrase] = useState('')
    const [showModal, setShowModal] = useState(false)

    const handleSidebar = () => {
        setToggleSidebar(!toggleSidebar)
    }
    const handleSearch = (input: string) => {
        setSearchPhrase(input)
    }
    const paths = location.pathname.split('/')

    return (
        <header className="h-[72px] lg:h-[101px] bg-white sticky top-0 flex justify-between z-20">
            <div className="px-3 lg:hidden">
                <div
                    onClick={handleSidebar}
                    className="w-[30px] h-[30px] block lg:hidden lg:opacity-0"
                >
                    <div className="w-[44px] h-[30px] mt-4">
                        <img src={hamburger} alt="hamburger" />
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full justify-center">
                <div className="w-full flex items-center justify-end md:justify-between py-4 md:border-b-2 md:border-gray-200">
                    <div className="pl-6 hidden md:block">
                        {/* <SeachInput
                            value={searchPhrase}
                            handleSearch={handleSearch}
                        /> */}
                    </div>
                    <div className="flex gap-3 items-center pr-4 relative">
                        <div className="hidden md:block">
                            <img src={question} alt="question" />
                        </div>
                        <div className="hidden md:block">
                            <img src={notification} alt="notification" />
                        </div>
                        <span className="hidden md:block Poppins text-sm capitalize">
                            {curUserName}
                        </span>
                        <div
                            className="flex gap-1 cursor-pointer"
                            onClick={() => {
                                setShowModal(true)
                            }}
                        >
                            <img
                                src={avatar}
                                className="w-[25px] h-[25px] border-none"
                            />
                            <img src={arrowDown} alt="arrow down" />
                        </div>
                        <ProfileDropdown
                            isOpen={showModal}
                            setIsOpen={setShowModal}
                        />
                    </div>
                </div>
                <div className="pl-6 py-2 hidden  lg:flex ">
                    {breadcrumbs?.map((b, idx) => (
                        <p className={`${idx !== 0 && 'pl-2'}`}>
                            {b} {idx !== breadcrumbs.length - 1 && '/'}
                        </p>
                    ))}
                    {!breadcrumbs?.length &&
                        paths.map((path, index) => {
                            const Arr = [...paths]
                            const link = Arr.splice(1, index).join('/')
                            return (
                                <Link
                                    key={index}
                                    className="text-blue-600"
                                    to={`/${link}`}
                                >
                                    {path}
                                    {paths[index + 1] && path ? '/' : ''}
                                </Link>
                            )
                        })}
                </div>
            </div>
        </header>
    )
}
