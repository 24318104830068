import React, { createContext, useState } from 'react'

export const defaultValue = {
    operationInfo: {
        operationType: {
            value: '',
            label: '',
        },
        operationDate: '',
        airline: {
            value: '',
            label: '',
        },
        aircraftCategory: {
            value: '',
            label: '',
        },
        handlingType: {
            value: '',
            label: '',
        },
        // id: 0,
    },
    selectFlight: {},
    serviceRendered: {},
    selectedContractService: {
        activeContracts: [],
        code: '',
        description: 'hope',
        icon: '',
        id: 0,
        name: '',
    },
    contractId: 0,
    entryType: {
        name: 'Scheduled',
        id: 0,
    },
}

interface IEntryType {
    name: string
    id: number
}

interface IContext {
    operationInfo: IOperationInfo
    selectFlight: any
    serviceRendered: any
    selectedContractService: ISelectedContractService
    contractId: number
    entryType: IEntryType
}
interface IData {
    allDataGHS: IContext
    setAllDataGHS: Function
}
interface IOperationInfo {
    operationType: {
        value: string
        label: string
    }
    operationDate: string
    airline: {
        value: string
        label: string
    }
    aircraftCategory: {
        value: string
        label: string
    }
    handlingType: {
        value: string
        label: string
    }
    id?: number
}
interface ISelectFlight {
    eta: string
    etd: string
    aircraftType: {
        value: string
        label: string
    }
    flightA: {
        value: string
        label: string
    }
    flightB: {
        value: string
        label: string
    }
    origin: {
        value: string
        label: string
    }
    destination: {
        value: string
        label: string
    }
}

interface ISelectedContractService {
    activeContracts: any[]
    code: string
    description: string
    icon: string
    id: number
    name: string
}

export const ScheduledContext = createContext<IData>({
    allDataGHS: defaultValue,
    setAllDataGHS: () => {},
})

export function ScheduledProvider({ children }: any) {
    const [allDataGHS, setAllDataGHS] = useState(defaultValue)
    return (
        <ScheduledContext.Provider value={{ allDataGHS, setAllDataGHS }}>
            {children}
        </ScheduledContext.Provider>
    )
}
