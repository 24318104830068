import { lazy, Suspense } from 'react'
const AssetManagement = lazy(
    () => import('../../../../pages/Settings/Asset/Assets')
)
const AddAsset = lazy(
    () => import('../../../../pages/Settings/Asset/Create/Create')
)
const EditAsset = lazy(
    () => import('../../../../pages/Settings/Asset/Edit/Edit')
)
import { getUserPermissions } from '../../../../utils/localStorage'
import { LandingBg } from '../../../LandingBg/LandingBg'
import { IRoute } from '../PermissionRoutes'

const permissions = getUserPermissions()

export const assetRoutes: IRoute[] = [
    {
        path: 'settings/asset',
        comp: <AssetManagement />,
        isAllowed: permissions.has('settings_asset'),
    },
    {
        path: 'settings/asset/add',
        comp: <AddAsset />,
        isAllowed: permissions.has('settings_asset'),
    },
    {
        path: 'settings/asset/edit',
        comp: <EditAsset />,
        isAllowed: permissions.has('settings_asset'),
    },
]
