import { lazy, Suspense } from 'react'
const RolesPermission = lazy(
    () => import('../../../../pages/Settings/Roles/Roles')
)
import { getUserPermissions } from '../../../../utils/localStorage'
import { LandingBg } from '../../../LandingBg/LandingBg'
import { IRoute } from '../PermissionRoutes'

const permissions = getUserPermissions()

export const roleRoutes: IRoute[] = [
    {
        path: 'settings/roles',
        comp: <RolesPermission />,
        isAllowed: permissions.has('settings_roles_permissions'),
    },
]
