import { lazy } from 'react'
import { Suspense } from 'react'
const AddUser = lazy(
    () => import('../../../../pages/Settings/User/AddUser/AddUser')
)
const UserDetails = lazy(
    () => import('../../../../pages/Settings/User/details')
)
const EditUser = lazy(
    () => import('../../../../pages/Settings/User/EditUser/EditUser')
)
const UserManagement = lazy(
    () => import('../../../../pages/Settings/User/User')
)
import { getUserPermissions } from '../../../../utils/localStorage'
import { LandingBg } from '../../../LandingBg/LandingBg'
import { IRoute } from '../PermissionRoutes'

const permissions = getUserPermissions()

export const userRoutes: IRoute[] = [
    {
        path: 'settings/user',
        comp: <UserManagement />,
        isAllowed: permissions.has('settings_user'),
    },
    {
        path: 'settings/user/create',
        comp: <AddUser />,
        isAllowed: permissions.has('settings_user'),
    },
    {
        path: 'settings/user/edit',
        comp: <EditUser />,
        isAllowed: permissions.has('settings_user'),
    },
    {
        path: 'settings/user/detail',
        comp: <UserDetails />,
        isAllowed: permissions.has('settings_user'),
    },
]
