import React, { createContext, useState } from 'react'

const defaultValue = {
    toggleSidebar: false,
    setToggleSidebar: () => {},
    setShowDropDown: () => {},
    showDropDown: null,
    currentTab: '',
    setCurrentTab: () => {},
    currentTabIndex: -1,
    setCurrentTabIndex:() => {},
    leaveOpen: true,
    setLeaveOpen: () => {}
}

type IContext = {
    toggleSidebar: Boolean,
    setToggleSidebar: Function,
    setShowDropDown: Function,
    showDropDown: null,
    currentTab: string,
    setCurrentTab: Function,
    currentTabIndex?:number,
    setCurrentTabIndex?: Function
    leaveOpen?:boolean
    setLeaveOpen?: Function
}

export const UiContext = createContext<IContext>(defaultValue)

export function UiProvider({ children }: any) {
    const [toggleSidebar, setToggleSidebar] = useState(false)
    const [showDropDown, setShowDropDown] = useState(null);
    const [currentTab, setCurrentTab] = useState('Overview');
    const [currentTabIndex, setCurrentTabIndex] = useState(-1);
    const [leaveOpen, setLeaveOpen] = useState(true);
     
    return (
        <UiContext.Provider
            value={{
                toggleSidebar,
                setToggleSidebar,
                showDropDown,
                setShowDropDown,
                currentTab,
                setCurrentTab,
                currentTabIndex,
                setCurrentTabIndex,
                leaveOpen,
                setLeaveOpen
            }}
        >
            {children}
        </UiContext.Provider>
    )
}
