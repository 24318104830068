import { lazy, Suspense } from 'react'
import { ContractFormProvider } from '../../../../pages/Settings/Contract/context/contractFormCtx'
import { ContractFormGHSProvider } from '../../../../pages/Settings/Contract/context/contractFormGHS'
const Contract = lazy(
    () => import('../../../../pages/Settings/Contract/Contract')
)
const ContractEditForm = lazy(
    () =>
        import('../../../../pages/Settings/Contract/Edit/AOG/ContractEditForm')
)
const EditPreview = lazy(
    () =>
        import(
            '../../../../pages/Settings/Contract/Edit/AOG/EditPreview/EditPreview'
        )
)
const ContractForm = lazy(
    () => import('../../../../pages/Settings/Contract/New/AOG/ContractForm')
)

const ContractPreview = lazy(
    () =>
        import(
            '../../../../pages/Settings/Contract/New/AOG/ContractPreview/ContractPreview'
        )
)
const ContractPreviewGHS = lazy(
    () =>
        import(
            '../../../../pages/Settings/Contract/New/GHS/ContractPreview/ContractPreview'
        )
)
const EditPreviewGHS = lazy(
    () =>
        import(
            '../../../../pages/Settings/Contract/Edit/GHS/EditPreview/EditPreview'
        )
)
const GHSContractForm = lazy(
    () => import('../../../../pages/Settings/Contract/New/GHS/ContractForm')
)
const ContractEditFormGHS = lazy(
    () =>
        import('../../../../pages/Settings/Contract/Edit/GHS/ContractEditForm')
)
const NewContract = lazy(
    () => import('../../../../pages/Settings/Contract/New/NewContract')
)
const ViewContracDetails = lazy(
    () =>
        import(
            '../../../../pages/Settings/Contract/ViewDetails/AOG/ViewContracDetails'
        )
)

const ViewContracDetailsGHS = lazy(
    () =>
        import(
            '../../../../pages/Settings/Contract/ViewDetails/GHS/ViewContractDetailsGHS'
        )
)
import { IRoute } from '../PermissionRoutes'
import { getUserPermissions } from '../../../../utils/localStorage'
import { LandingBg } from '../../../LandingBg/LandingBg'

const permissions = getUserPermissions()

export const contractRoutes: IRoute[] = [
    {
        path: 'settings/contract',
        comp: (
            <ContractFormProvider>
                <Contract />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/add',
        comp: (
            <ContractFormProvider>
                <NewContract />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/edit/AOG/:contractId',
        comp: (
            <ContractFormProvider>
                <ContractEditForm />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },

    {
        path: 'settings/contract/AOG/view/:contractId',
        comp: (
            <ContractFormProvider>
                <ViewContracDetails />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/GHS/view/:contractId',
        comp: (
            <ContractFormProvider>
                <ViewContracDetailsGHS />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/new/AOG/preview',
        comp: (
            <ContractFormProvider>
                <ContractPreview />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },

    {
        path: 'settings/contract/edit/AOG/preview',
        comp: (
            <ContractFormProvider>
                <EditPreview />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/new/AOG/:serviceType',
        comp: (
            <ContractFormProvider>
                <ContractForm />
            </ContractFormProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/new/GHS/:serviceType',
        comp: (
            <ContractFormGHSProvider>
                <GHSContractForm />
            </ContractFormGHSProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/edit/GHS/:contractId',
        comp: (
            <ContractFormGHSProvider>
                <ContractEditFormGHS />
            </ContractFormGHSProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/new/GHS/preview',
        comp: (
            <ContractFormGHSProvider>
                <ContractPreviewGHS />
            </ContractFormGHSProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
    {
        path: 'settings/contract/edit/GHS/preview',
        comp: (
            <ContractFormGHSProvider>
                <EditPreviewGHS />
            </ContractFormGHSProvider>
        ),
        isAllowed: permissions.has('settings_contract'),
    },
]
