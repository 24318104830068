import React, { createContext, useState } from 'react'

const defaultValue = {
    dataCapture: {
        selectedContractService: {
            activeContracts: [],
            code: '',
            description: null,
            icon: null,
            id: '',
            name: '',
        },
        selectedPassengers: [],
        selectedFile: null,
        ContractId: {
            value: '',
            label: 'Select Contract',
            partnerOrganizationId: '',
        },
        ProducerId: {
            value: '',
            label: 'Select Receiving Airline',
        },
        ConsumerId: {
            value: '',
            label: '',
        },
        ForwardingFlightNumber: {
            value: '',
            label: 'Select Forwarding Flight',
        },
        ReceivingFlightNumber: {
            value: '',
            label: 'Select Receiving Flight',
        },
        Origin: {
            value: '',
            label: 'Select Flight Origin',
        },
        Destination: {
            value: '',
            label: 'Select Flight Destination',
        },
        ScenerioCaseName: {
            value: '',
            label: 'Select Scenario Case',
        },
        FlightDate: '',
        dataItems: [],
        fileUrl: '',
        ExcessBaggageWeight: 0,
    },
}

interface selectType {
    value: string
    label: string
}

interface IDataCapture {
    selectedContractService: {
        activeContracts: any[]
        code: string
        description: null
        icon: null
        id: string
        name: string
    }
    selectedPassengers: any[]
    selectedFile: any
    ContractId: {
        value: string
        label: string
        partnerOrganizationId: string
    }
    ProducerId: selectType
    ConsumerId: selectType
    ForwardingFlightNumber: selectType
    ReceivingFlightNumber: selectType
    Origin: selectType
    Destination: selectType
    ScenerioCaseName: selectType
    FlightDate: string
    dataItems: any[]
    fileUrl: string
    ExcessBaggageWeight: number
}

interface IContext {
    dataCapture: IDataCapture
}

interface IData {
    allData: IContext
    setAllData: React.Dispatch<React.SetStateAction<any>>
    resetAll: Function
}

export const DataCaptureCtx = createContext<IData>({
    allData: defaultValue,
    setAllData: async () => {},
    resetAll: async () => {},
})

export function DataCaptureProvider({ children }: any) {
    const [allData, setAllData] = useState(defaultValue)

    const resetAll = () => {
        setAllData(defaultValue)
    }

    return (
        <DataCaptureCtx.Provider value={{ allData, setAllData, resetAll }}>
            {children}
        </DataCaptureCtx.Provider>
    )
}
