import { searchUsers } from './../utils/services/settings/user';
import { useState } from 'react'
import { useQuery } from 'react-query'


export default function useUsers() {
    const [users, setUsers] = useState([])
    const payload = {
        pageSize: 100,
        from: 0,
        parameter: {},
    }
    const { refetch } = useQuery(['GetAllUsers'], 
    () => searchUsers(payload),
     {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setUsers(response.data.items)
        },
    })

    const getAllUsers = () => {
        refetch();
    }

    return {users, getAllUsers}
}