import React from 'react'
import { Spinner } from '../Spinner/Spinner'

interface IOutineButton {
    label: string
    handleClick?: any
    size?: 'Large' | 'Medium' | 'Small' | 'Dynamic'
    type?: any
    isLoading?: boolean
    disabled?: boolean
    color? : string
}

export function ButtonOutlined({
    label,
    handleClick,
    size = 'Large',
    type,
    isLoading,
    disabled,
    color
}: IOutineButton): JSX.Element {
    if (size === 'Small') {
        return (
            <button
                type={type}
                className={`flex justify-center items-center cursor-pointer ${color? color: 'border border-[#219653] text-[#219653]'}  text-xs font-Inter px-6 py-1 capitalize rounded-sm`}
                onClick={handleClick}
                disabled={disabled}
            >
                {/* {label} */}
                {isLoading ? <Spinner /> : label}
            </button>
        )
    }

    if (size === 'Medium') {
        return (
            <button
                type={type}
                className="flex justify-center items-center cursor-pointer border border-[#219653] text-[#219653] h-10 w-36 text-xs font-Inter font-semibold capitalize rounded-sm"
                onClick={handleClick}
                disabled={disabled}
            >
                {isLoading ? <Spinner /> : label}
            </button>
        )
    }

    if (size === 'Dynamic') {
        return (
            <button
                type={type}
                className="flex justify-center items-center cursor-pointer border border-[#219653] text-[#219653] h-10  w-24 md:w-36 text-xs font-Inter font-semibold capitalize rounded-sm"
                onClick={handleClick}
                disabled={disabled}
            >
                {isLoading ? <Spinner /> : label}
            </button>
        )
    }
    return (
        <button
            type={type}
            className="flex justify-center items-center cursor-pointer border border-[#219653] text-[#219653] h-10 w-36 text-xs font-Inter font-semibold capitalize rounded-sm"
            onClick={handleClick}
            disabled={disabled}
        >
            {isLoading ? <Spinner /> : label}
        </button>
    )
}
