import { ReactElement } from 'react'
import logo from '../../../assets/img/logo.png'
import Menu from './Menu'

function AdminSidebar(): ReactElement {
    return (
        <aside className="w-[220px] bg-white min-h-screen fixed top-0 left-0 hidden lg:block">
            <div className="p-4 flex justify-between items-center">
                <div className="w-[84px] h-[30px]">
                    <img src={logo} alt="logo" />
                </div>
            </div>

            <Menu />
        </aside>
    )
}

export default AdminSidebar
