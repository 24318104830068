import { devInstance } from '../../axios/axiosInstance'

export const searchUsers = async (payload: any) => {
    const data = await devInstance.post('/UserManagement/SearchUser', payload)
    return data
}

export const addUser = async (payload: any) => {
    const data = await devInstance.post('/UserManagement/CreateUser', payload)
    return data
}

export const updateUser = async (payload: any) => {
    const data = await devInstance.post('/UserManagement/UpdateUser', payload)
    return data
}

export const activateUser = async (payload: any) => {
    const data = await devInstance.post(`/UserManagement/ActivateUser?UserId=${payload.userId}`, payload)
    return data
}

export const deactivateUser = async (payload: any) => {
    const data = await devInstance.post(`/UserManagement/DeactivateUser?UserId=${payload.userId}`, payload)
    return data
}
