import { FC, useContext, useEffect } from 'react'
import { UiContext } from '../../context/uiContext'
import useContractService from '../../hooks/useContractService'
import AdminContent from './Content'
import { AdminHeader } from './Header'
import AdminSidebar from './Sidebar'
import MobileSidebar from './Sidebar/Mobile/Mobile'
interface Props {
    breadcrumbs?: any
}
const GenericLayout: FC<Props> = ({ children, breadcrumbs }) => {
    const {
        toggleSidebar,
        setToggleSidebar,
        showDropDown,
        setShowDropDown,
        leaveOpen,
    } = useContext(UiContext)
    const handleLayoutClick = () => {
        if (showDropDown && leaveOpen) {
            setShowDropDown(null)
        }
        if (toggleSidebar) {
            // setToggleSidebar(false);
        }
    }
    const { contractService, fetchContractService } = useContractService()

    useEffect(() => {
        fetchContractService()
    }, [])

    return (
        <main
            className="flex relative h-screen bg-[#F1F2F3] w-screen overflow-x-hidden"
            onClick={handleLayoutClick}
        >
            <MobileSidebar />
            <AdminSidebar />
            <AdminContent>
                <AdminHeader breadcrumbs={breadcrumbs} />
                {children}
            </AdminContent>
        </main>
    )
}

export default GenericLayout
