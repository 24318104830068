import { devInstance } from '../../axios/axiosInstance'

export const fetchRoles = async () => {
    const response = await devInstance.get('/Role/GetAllRoles');
    return response
}

export const fetchRolesClassification = async () => {
    const response = await devInstance.get('/Role/GetAllRoleClassifications');
    return response
}
