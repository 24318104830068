import { Suspense, lazy } from 'react'

const DisputeReport = lazy(
    () => import('../../../pages/Reports/DisputeReport/DisputeReport')
)
const PaymentReport = lazy(
    () => import('../../../pages/Reports/PaymentReport/PaymentReport')
)
const SettlementReport = lazy(
    () => import('../../../pages/Reports/SettlementReport/SettlementReport')
)
import { getUserPermissions } from '../../../utils/localStorage'
import { LandingBg } from '../../LandingBg/LandingBg'
import { IRoute } from './PermissionRoutes'

const permissions = getUserPermissions()

export const reportRoutes: IRoute[] = [
    {
        path: 'reports',
        comp: <PaymentReport />,
        isAllowed: permissions.has('reports'),
    },
    {
        path: 'reports/payment',
        comp: <PaymentReport />,
        isAllowed: permissions.has('reports'),
    },
    {
        path: 'reports/settlement',
        comp: <SettlementReport />,
        isAllowed: permissions.has('reports'),
    },
    {
        path: 'reports/dispute',
        comp: <DisputeReport />,
        isAllowed: permissions.has('reports'),
    },
]
