import React, { ReactElement, useState, useEffect } from 'react'
import GenericLayout from '../../../components/GenericLayout'
import { useQuery, useMutation } from 'react-query'
import { useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { BackArrow } from '../../../components/Icons/BackArrow'
import { ButtonOutlined } from '../../../components/UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../../components/UIKit/ButtonPrimary/Button'
import { Input } from '../../../components/UIKit/Input/Input'
import Select from 'react-select'
import { customStyles } from '../../../utils/selectStyles'
import successIcon from '../../../assets/img/add-icon.svg'
import useRoles from '../../../hooks/useRoles'
import useUsers from '../../../hooks/useUsers'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PlusIcon from '../../../assets/img/add-icon.svg'
import { dynamicSchema } from '../data'
import useOrganizations from '../../../hooks/useOrganizations'
import { createWorkFlow } from '../../../utils/services/workflowApprovals'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { getSavedState } from '../../../utils/localStorage'
import NestedArray from '../Nested/nestedData'
import { json } from 'stream/consumers'
import { FormProvider } from 'react-hook-form'

let renderCount = 0

const schema = yup.object().shape({
    role: yup.object().shape({
        value: yup.string().required('role is required'),
        label: yup.string().required(),
    }),
})

export function AddWorkFlow(): ReactElement {
    const navigate = useNavigate()
    const { roles, getAllRoles } = useRoles()
    const { users, getAllUsers } = useUsers()
    const { organizations, getOrganizations } = useOrganizations()
    const CCPUser = getSavedState('CCPUser')

    // const {
    //     register,
    //     control,
    //     handleSubmit,
    //     formState: { errors },
    //     setValue,
    //     watch,
    //     refetch,
    //     getValues,
    //     reset: resetForm,
    // } = useForm({
    //     resolver: yupResolver(schema),
    // })
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            workflowSet: [
                {
                    taskName: '',
                },
            ],
        },
    })

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState,
        setValue,
        watch,
    } = methods
    const { errors } = formState
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'workflowSet',
    })
    const dd = watch()
    const currentValues = watch()

    let payload = {
        organizationId: '',
    }
    if (currentValues?.organization?.value) {
        payload = {
            organizationId: currentValues?.organization?.value,
        }
    }

    const { mutateAsync, isLoading, isError, error, data } = useMutation(
        createWorkFlow,
        {
            onSuccess: ({ data }) => {
                // refetch()
                toast.success('workflow created successfully')
                navigate('/workflow')
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        }
    )
    const onSubmit = (data: any) => {
        mutateAsync({
            name: data.name,
            description: data.description,
            levels: 1,
            organizationId: CCPUser?.organizationId,
            workFlowProcess: [
                {
                    processName: data.taskName,
                    processLevel: 1,
                    users: [
                        {
                            userId: parseInt(data.user.value),
                            roleId: parseInt(data.role.value),
                            workFlowProcessId: 0,
                        },
                    ],
                },
            ],
        })
    }

    useEffect(() => {
        getOrganizations()
        getAllRoles()
        getAllUsers()
    }, [])

    renderCount++
    console.log(fields, 'fields')
    // console.log(workflowSet, 'work');

    return (
        <GenericLayout breadcrumbs={['Workflow', 'Add']}>
            <div className="w-full h-32 bg-[#219653]">
                <div
                    className="flex gap-2 item-center cursor-pointer"
                    style={{ marginLeft: '85%', paddingTop: '20px' }}
                    onClick={() => navigate(-1)}
                >
                    <BackArrow className="stroke-white" />
                    <p className="md:text-base text-white">Back</p>
                </div>
                <div className="bg-white lg:w-4/5 mb-4  z-10 mx-5 rounded p-9 mt-16">
                    <div className="font-bold text-2xl text-[#002555]">
                        Create New Workflow
                    </div>
                    {/* upper fields */}
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="w-full md:pl-4 md:flex md:flex-wrap md:justify-between">
                                <div className="mt-3 w-full md:w-[calc(49%)]">
                                    <div className="flex mb-3">
                                        <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                            Name
                                        </label>
                                        <span className="mr-5 text-[#F3477A]">
                                            *
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter details..."
                                        name="name"
                                        register={register('name')}
                                        loading={false}
                                    />
                                    {/* <p className="text-xs text-red-600">
                                    {errors?.name?.message}
                                </p> */}
                                </div>
                                <div className="mt-3 w-full md:w-[calc(49%)]">
                                    <div className="flex mb-3">
                                        <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                            Description
                                        </label>
                                        <span className="mr-5 text-[#F3477A]">
                                            *
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter details..."
                                        loading={false}
                                        name="description"
                                        register={register('description')}
                                    />
                                    {/* <p className="text-xs text-red-600">
                                    {errors?.name?.message}
                                </p> */}
                                </div>
                            </div>
                            {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
                            {/* the steps */}
                            {fields.map((item: any, index: number) => (
                                <div key={item.id} className="flex mt-6">
                                    <div className="flex-col flex-wrap">
                                        <div
                                            style={{
                                                border: '2px solid green',
                                            }}
                                            className="w-14 h-14 bg-slate-200 rounded-full border-green-500 flex justify-center items-center text-center p-5 shadow-xl text-green-700"
                                        >
                                            {index + 1}
                                        </div>

                                        <div className="py-4">
                                            <div style={{
                                                borderRight: "2px dotted Green",
                                                height: "270px",
                                                position: "relative",
                                                right: "55%",
                                            }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className={'flex flex-col '}>
                                        <div
                                            style={{
                                                position: 'relative',
                                                marginTop: '12px',
                                            }}
                                            className="text-green-700 pl-5"
                                        >
                                            Step {index + 1}
                                        </div>
                                        {index === 0 && (
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    marginTop: '190%',
                                                }}
                                                className="text-green-700 pl-5"
                                            >
                                                Add another step
                                            </div>
                                        )}
                                    </div>

                                    <div className="md:pl-12 md:flex md:flex-wrap md:justify-between">
                                        <div className="mt-3 w-full md:w-[calc(57%)]">
                                            <div className="flex mb-3">
                                                <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                                    Enter Task Name
                                                </label>
                                            </div>
                                            <Input
                                                placeholder="General Payment Advice"
                                                name={`workflowSet[${index}].taskName`}
                                                //@ts-ignore
                                                defaultValue={item.taskName}
                                                type="text"
                                                register={register(
                                                    `workflowSet[${index}].taskName`
                                                )}
                                            />
                                        </div>

                                        <NestedArray
                                            nestIndex={index}
                                            {...{
                                                control,
                                                register,
                                                errors,
                                                setValue,
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => {
                                    append({ taskName: '' })
                                }}
                            >
                                <img
                                    src={successIcon}
                                    alt="add icon"
                                    className="w-14 text-green-700"
                                />
                            </button>
                            <div className="mt-8 flex gap-4 justify-center">
                                <ButtonOutlined
                                    label="Cancel"
                                    handleClick={() => null}
                                    // disabled={isLoading}
                                />
                                <ButtonPrimary
                                    type="submit"
                                    label="Create Workflow"
                                    // isLoading={isLoading}
                                    // disabled={isLoading}
                                />
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {/* <span className="counter">Render Count: {renderCount}</span> */}
            </div>
        </GenericLayout>
    )
}
