import React, { ReactElement, useState, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { Input } from '../../../components/UIKit/Input/Input'
import Select from 'react-select'
import { customStyles } from '../../../utils/selectStyles'
import useRoles from '../../../hooks/useRoles'
import useUsers from '../../../hooks/useUsers'
import PlusIcon from '../../../assets/img/add-icon.svg'
import { useForm } from 'react-hook-form'
import { FormProvider } from "react-hook-form";


let renderCount = 0

//@ts-ignore
export default ({
    nestIndex,
    // control,
    // setValue,
    // register,
    errors,
}: {
    nestIndex: any
    control: any
    register: any
    errors: any
    setValue: any
}) => {

    const { roles, getAllRoles } = useRoles()
    const { users, getAllUsers } = useUsers()

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            workflowSt: [{
                roleName: "",
                userName: ""
            }],
        }

    })

    const { control, register, handleSubmit, getValues, formState, setValue, watch } =
        methods
    const { fields, remove, append } = useFieldArray({
        control,
        name: `workflowSet[${nestIndex}].nestedArray`
    });
    useEffect(() => {
        getAllRoles()
        getAllUsers()
        append()


    }, [])
    console.log("nested", fields);
    // console.log("arr", NestedArray);

    renderCount++;

    return (
        <>

            <FormProvider {...methods}>
                <form>
                    {fields.map((item: any, k: number) => {
                        return (
                            <div className="md:flex md:flex-wrap md:justify-between"
                                key={item.id}
                            >
                                <div className=" md:w-[calc(45%)]" >

                                    <div className="flex mb-3">
                                        <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                            Role Name
                                        </label>
                                    </div>
                                    <Select
                                        styles={customStyles}
                                        name={`workflowSet[${nestIndex}].nestedArray[${k}].roleName`}
                                        options={roles?.map(
                                            (role: {
                                                id: string
                                                roleName: string
                                            }) => {
                                                return {
                                                    value: role.id,
                                                    label: role.roleName,
                                                }
                                            }
                                        )}
                                        defaultValue={item.roleName}
                                        placeholder="Select..."
                                        onChange={(newValue: any) => {
                                            setValue(
                                                `workflowSet[${nestIndex}].roleName`,
                                                newValue
                                            )
                                        }}
                                    />
                                    <p className="text-xs text-red-600">
                                        {errors?.role?.value?.message}
                                    </p>
                                </div>
                                <div className="md:w-[calc(45%)]">
                                    <div className="flex mb-3">
                                        <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                            User Name
                                        </label>
                                    </div>

                                    <Select
                                        placeholder="Select..."
                                        styles={customStyles}
                                        name={`workflowSet[${nestIndex}].nestedArray[${k}].userName`}
                                        defaultValue={item.userName}

                                        options={users?.map(
                                            (user: {
                                                id: string
                                                firstName: string
                                            }) => {
                                                return {
                                                    value: user.id,
                                                    label: user.firstName,
                                                }
                                            }
                                        )}
                                        onChange={(newValue: any) => {
                                            setValue(
                                                `workflowSet[${nestIndex}].roleName`,
                                                newValue
                                            )
                                        }}
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={() => remove(k)}

                                    className="text-xs ml-6 text-red-600">Remove
                                </button>
                            </div>

                        );
                    })}
                    <button
                        type="button"
                        onClick={() => append({
                            roleName: "",
                            userName: ""
                        })}

                    >
                        <img src={PlusIcon} alt="plus"
                            className=" flex flex-col w-6"
                        />
                    </button>
                </form>
            </FormProvider>

        </>
    );
};
