import { Suspense, lazy } from 'react'

import { Route, Routes } from 'react-router-dom'
const AuditTrail = lazy(() => import('../../../pages/AuditTrail/AuditTrail'))
const Dashboard = lazy(() => import('../../../pages/Dashboard/Dashboard'))
const Disputes = lazy(() => import('../../../pages/Disputes/Disputes'))
const MakerChecker = lazy(
    () => import('../../../pages/MakerChecker/MakerChecker')
)
const MyTasks = lazy(() => import('../../../pages/MyTasks/MyTasks'))

const PageNotFound = lazy(() => import('../../../pages/NotFound/PageNotFound'))

const Resources = lazy(() => import('../../../pages/Resources/Resources'))

import { WorkFlow } from '../../../pages/WorkFlow/WorkFlow'

import { Access } from '../../AuthRoute/AuthRoute'
import { contractServiceRoutes } from './ContractService'
import { dataReconcilliationRoutes } from './DataReconcilliation'
import { dataCaptureRoutes } from './DataCapture'
import { settlementRoutes } from './Settlement'
import { reportRoutes } from './Report'
import { settingsRoutes } from './Settings'
import { profileRoutes } from './Profile'
const Overview = lazy(() => import('../../../pages/Overview/Overview'))
import { workflowRoutes } from './workflow'
import { DataCaptureProvider } from '../../../pages/DataCapture/context/dcContext'
import { ScheduledProvider } from '../../../pages/DataCapture/GroundHandling/Scheduled/context/scheduledContext'
import { UnscheduledProvider } from '../../../pages/DataCapture/GroundHandling/Unscheduled/context/unScheduledContext'
import { ReconciliationProvider } from '../../../pages/DataReconcilliation/context'
import { getUserPermissions } from '../../../utils/localStorage'
import { AdhocProvider } from '../../../pages/DataCapture/AdhocTransfer/context/AdhocContext'

export type IRoute = {
    path: string
    comp: JSX.Element
    isAllowed: boolean
}

const PermissionRoutes = () => {
    const permissions = getUserPermissions()
    console.log("weHere");
    
    return (
        <ReconciliationProvider>
            <UnscheduledProvider>
                <ScheduledProvider>
                    <DataCaptureProvider>
                        <AdhocProvider>
                        <Routes>
                            {[
                                ...dataReconcilliationRoutes,
                                ...contractServiceRoutes,
                                ...reportRoutes,
                                ...settlementRoutes,
                                ...dataCaptureRoutes,
                                ...settingsRoutes,
                                ...workflowRoutes,
                            ].map((cs) => (
                                <Route
                                    key={cs.path}
                                    path={cs.path}
                                    element={
                                        <Access
                                            comp={cs.comp}
                                            isAllowed={cs.isAllowed}
                                        />
                                    }
                                />
                            ))}

                            <Route
                                path="overview"
                                element={
                                    <Access comp={<Overview />} isAllowed={true} />
                                }
                            />

                            <Route
                                path="overview"
                                element={
                                    <Access comp={<Overview />} isAllowed={true} />
                                }
                            />

                            <Route
                                path="disputes"
                                element={
                                    <Access comp={<Disputes />} isAllowed={true} />
                                }
                            />
                            <Route
                                path="tasks"
                                element={
                                    <Access 
                                        comp={<MyTasks />} 
                                        isAllowed={permissions.has(
                                            'my_tasks'
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="tasks/:id"
                                element={
                                    <Access 
                                        comp={<MyTasks />} 
                                        isAllowed={permissions.has(
                                            'my_tasks'
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="audit"
                                element={
                                    <Access
                                        comp={<AuditTrail />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            <Route
                                path="resources"
                                element={
                                    <Access comp={<Resources />} isAllowed={true} />
                                }
                            />
                            <Route
                                path="dashboard"
                                element={
                                    <Access comp={<Dashboard />} isAllowed={true} />
                                }
                            />
                            <Route
                                path="workflow"
                                element={
                                    <Access comp={<WorkFlow />} isAllowed={true} />
                                }
                            />
                            <Route
                                path="maker-checker"
                                element={
                                    <Access
                                        comp={<MakerChecker />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                        </AdhocProvider>
                    </DataCaptureProvider>
                </ScheduledProvider>
            </UnscheduledProvider>
        </ReconciliationProvider>
    )
}

export default PermissionRoutes
