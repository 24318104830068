import React, { createContext, useState } from 'react';

const defaultValue = {
    selectedContractService: {
        activeContracts: [],
        code: '',
        description: null,
        icon: null,
        id: '',
        name: '',
    },
}

interface IReconciliation {
    selectedContractService: {
        activeContracts: any[]
        code: string
        description: null
        icon: null
        id: string
        name: string
    }
}

interface IData {
    allData: IReconciliation
    setAllData: React.Dispatch<React.SetStateAction<any>>
    resetAll: Function
}

export const ReconciliationCtx = createContext<IData>({
    allData: defaultValue,
    setAllData: async () => {},
    resetAll: async () => {},
})

export function ReconciliationProvider({ children }: any) {
    const [allData, setAllData] = useState(defaultValue)

    const resetAll = () => {
        setAllData(defaultValue)
    }

    return (
        <ReconciliationCtx.Provider value={{ allData, setAllData, resetAll }}>
            {children}
        </ReconciliationCtx.Provider>
    )
}
