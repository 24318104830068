import { assetRoutes } from './assets'
import { contractRoutes } from './contract'
import { contractServiceRoutes } from './contractService'
import { organizationRoutes } from './organization'
import { roleRoutes } from './roles'
import { userRoutes } from './user'

export const settingsRoutes = [
    ...assetRoutes,
    ...contractRoutes,
    ...contractServiceRoutes,
    ...organizationRoutes,
    ...roleRoutes,
    ...userRoutes,
]
