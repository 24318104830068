import { Suspense, lazy, useState } from 'react'

const ActivityLog = lazy(() => import('../../../pages/DataCapture/ActivityLog'))

const AircraftManifest = lazy(
    () =>
        import(
            '../../../pages/DataCapture/DataEntry/AircraftManifest/AircraftManifest'
        )
)

const DataEntry = lazy(
    () => import('../../../pages/DataCapture/DataEntry/DataEntry')
)

const DataProcessing = lazy(
    () => import('../../../pages/DataCapture/DataProcessing/DataProcess')
)

const UnsheduledGHS = lazy(
    () => import('../../../pages/DataCapture/GroundHandling/Unscheduled/index')
)

const SheduledGHS = lazy(
    () => import('../../../pages/DataCapture/GroundHandling/Scheduled')
)

const CreateSheduledGHS = lazy(
    () => import('../../../pages/DataCapture/GroundHandling/Scheduled/Create')
)

const CreateUnSheduledGHS = lazy(
    () => import('../../../pages/DataCapture/GroundHandling/Unscheduled/Create')
)

const SummaryGHS = lazy(
    () => import('../../../pages/DataCapture/GroundHandling/Summary/Summary')
)
const SummaryUnscheduledGHS = lazy(
    () => import('../../../pages/DataCapture/GroundHandling/Unscheduled/Summary/Summary')
)

const AdhocTransfer = lazy(
    () => import('../../../pages/DataCapture/AdhocTransfer/Create/index')
)

import { LandingBg } from '../../LandingBg/LandingBg'
import { getUserPermissions } from '../../../utils/localStorage'
import { IRoute } from './PermissionRoutes'

const permissions = getUserPermissions()
// const AdhocTransferWrapper = () => {
//     const [formStep, setFormStep] = useState(0);
  
//     return <AdhocTransfer formStep={formStep} setFormStep={setFormStep} />;
//   };
export const dataCaptureRoutes: IRoute[] = [
    {
        path: 'data-capture',
        comp: <ActivityLog />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/activity-log',
        comp: <ActivityLog />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-entry',
        comp: <DataEntry />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/aircraft-manifest',
        comp: <AircraftManifest />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-process',
        comp: <DataProcessing />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-entry/ground-handling/scheduled',
        comp: <SheduledGHS />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-entry/ground-handling/summary',
        comp: <SummaryGHS />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-entry/ground-handling/summary-unscheduled',
        comp: (
            <Suspense fallback={<LandingBg />}>
                <SummaryUnscheduledGHS />
            </Suspense>
        ),
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-entry/ground-handling/scheduled/add',
        comp: <CreateSheduledGHS />,
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-entry/ground-handling/unscheduled/add',
        comp: (
            <Suspense fallback={<LandingBg />}>
                <CreateUnSheduledGHS />
            </Suspense>
        ),
        isAllowed: permissions.has('data_capture'),
    },
    {
        path: 'data-capture/data-entry/ground-handling/unscheduled',
        comp: <UnsheduledGHS />,
        isAllowed: permissions.has('data_capture'),
    },

    {
        path: 'data-capture/data-entry/adhoc-transfer',
        comp: <AdhocTransfer />,
        isAllowed: permissions.has('data_capture'),
    },
]
