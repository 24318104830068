import { FC, useState } from 'react'
import { PaginationComponent } from '../../components/UIKit/Pagination/PaginationComponent'
import { Link, useNavigate } from 'react-router-dom'
import ellipseIcon from '../../assets/img/ellipse.svg'
import {
    activateWorkFlow,
    deactivateWorkFlow,
} from '../../utils/services/workflowApprovals'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { AppModal } from '../../components/UserSettings/Modal'
import { ButtonOutlined } from '../../components/UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../components/UIKit/ButtonPrimary/Button'
import { Dropdown } from '../../components/UIKit/Dropdown/Dropdown'
import moment from 'moment'

export interface CardDataType {
    name: string
    description: string
    createdOn: string
    active: boolean
    id?: string
    status?: boolean
}

export const WorkFlowDetails: FC<{
    cardList: CardDataType[]
    refetch?: any
}> = ({ cardList, refetch }) => {
    const [showDropdown, setShowDropdown] = useState<any>()
    const [showModal, setShowModal] = useState<any>()
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useState<any>()
    const [currentUserStatus, setCurrentUserStatus] = useState<any>()

    const handleClickOpen = (id: any) => {
        setCurrentUser(id)
        setShowModal(true)
    }

    const handleStatus = () => {
        console.log(currentUserStatus, 'user')

        if (currentUserStatus) {
            onDeactivate({
                id: currentUser,
            })
        } else {
            onActivate({
                id: currentUser,
            })
        }
    }
    const { mutateAsync: onActivate, isLoading: activateLoading } = useMutation(
        activateWorkFlow,

        {
            onSuccess: ({ data }) => {
                refetch()
                setShowModal(false)
                toast.success('Workflow activated successfully')
                setShowDropdown(false)
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        }
    )
    const { mutateAsync: onDeactivate, isLoading: deactivateLoading } =
        useMutation(deactivateWorkFlow, {
            onSuccess: ({}) => {
                refetch()
                setShowModal(false)
                toast.error('Workflow deactivated successfully')
                setShowDropdown(false)
            },
            onError: (error: AxiosError) => {
                setShowModal(false)
                setShowDropdown(false)
                toast.error(error?.response?.data?.errors[0])
            },
        })

    const CardListItem: FC<{ apiData: CardDataType }> = ({ apiData }) => (
        <div className="bg-white w-max min-w-full rounded px-5 py-8 my-5 flex justify-between shadow-md last:border-none md:overflow-x-hidden overflow-x-scroll">
            <div className="border-r border-r-gray-400 px-5 min-w-fit w-1/5 last:border-none">
                <div className="text-[#002555] font-Inter font-semibold text-sm mb-2">
                    {apiData.name}
                </div>
            </div>
            <div className="border-r border-r-gray-400 px-5  md:max-w-[18%] w-[40%]   last:border-none">
                <div className="text-[#4F4F4F] font-Inter text-xs mb-2">
                    DESCRIPTION
                </div>
                <div className="text-[#4F4F4F] font-Inter  break-words  text-xs">
                    {apiData.description}
                </div>
            </div>
            <div className="border-r border-r-gray-400 px-5 min-w-fit w-1/5 last:border-none">
                <div className="text-[#4F4F4F] font-Inter text-xs text-center mb-2">
                    DATE
                </div>
                <div className="text-[#002555] font-Inter text-xs text-center overflow-x-clip">
                    {moment(apiData.createdOn).format('DD/MMMM/YYYY')}
                </div>
            </div>

            <div className=" px-5 min-w-fit flex-1 last:border-none">
                <div className="text-[#4F4F4F] font-Inter text-xs text-center mb-2">
                    STATUS
                </div>
                <div
                    className={`text-[#002555] font-Inter text-xs text-center font-semibold ${
                        apiData.status ? `text-[#219653]` : `text-[#FF7870]`
                    }`}
                >
                    {apiData.status ? 'Active' : 'Inactive'}
                </div>
            </div>
            <div className="pl-1 w-[calc(12%)] flex items-center relative">
                <div
                    className="p-2 rounded-sm cursor-pointer bg-[#F0F0F0]"
                    onClick={() => setShowDropdown(apiData.id)}
                >
                    <img src={ellipseIcon} alt="ellipse" />
                </div>
                {showDropdown === apiData.id && (
                    <div className="absolute w-48 -right-4 z-50 rounded-md shadow-lg bg-white opacity-100 focus:outline-none py-2">
                        <p
                            className="font-Inter text-sm hover:bg-[#EBECF0] px-3 py-0.5 cursor-default"
                            // onClick={() =>
                            //     navigate(
                            //         `/settings/organization/detail/id=${apiData.id}`
                            //     )
                            // }
                        >
                            View Details
                        </p>
                        <p
                            className="font-Inter text-sm hover:bg-[#EBECF0] px-3 py-0.5 cursor-default"
                            onClick={() => {
                                console.log(apiData, 'apidata')

                                handleClickOpen(apiData.id)
                                setCurrentUserStatus(apiData.status)
                            }}
                        >
                            {`${
                                apiData.status ? 'Deactivate' : 'Activate'
                            } workflow`}
                        </p>
                        <Link
                            to={`/settings/organization/edit`}
                            state={apiData}
                        >
                            <p className="font-Inter text-sm hover:bg-[#EBECF0] px-3 py-1 cursor-pointer">
                                Edit
                            </p>
                        </Link>
                    </div>
                )}
                <Dropdown setIsOpen={setShowDropdown} isOpen={showDropdown} />
            </div>
        </div>
    )
    return (
        <>
            <div className="overflow-x-scroll md:overflow-x-hidden">
                {cardList.map((item, idx) => (
                    <CardListItem key={idx} apiData={item} />
                ))}
            </div>
            <AppModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                title="Confirm User Action"
            >
                <div className="flex flex-col items-center h-28">
                    <p className="text-xs">
                        {`Are you sure you want to ${
                            currentUserStatus ? 'deactivate' : 'activate'
                        } workflow`}
                    </p>
                    <div className="flex gap-4 mt-6">
                        <ButtonOutlined
                            size="Dynamic"
                            type="button"
                            label="Cancel"
                            handleClick={() => setShowModal(false)}
                        />
                        <ButtonPrimary
                            isLoading={activateLoading || deactivateLoading}
                            label="Confirm"
                            handleClick={handleStatus}
                        />
                    </div>
                </div>
            </AppModal>
        </>
    )
}
