import { log } from 'console';
import { devInstance } from '../axios/axiosInstance'

export const login = async (payload: any) => {
    const data = await devInstance.post('/users/login', payload)
    return data
}

export const searchWorkFlow = async (payload: any) => {
    const response = await devInstance.post('/WorkFlow/Search', payload)
    return response
}
export const createWorkFlow = async (payload: any) => {
    const response = await devInstance.post('/WorkFlow/CreateWorkFlow', payload)
    return response
}
export const activateWorkFlow = async (payload: any) => {
    const data = await devInstance.post(`/WorkFlow/Activate?id=${payload.id}`, payload)
    return data
}

export const deactivateWorkFlow = async (payload: any) => {
    const data = await devInstance.post(`/WorkFlow/Deactivate?id=${payload.id}`, payload)
    return
}