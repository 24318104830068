import { FC } from 'react'
import CCPLogo from '../../assets/img/logo.png'

const LandingLayout: FC = ({ children }) => {
    return (
        <div className="bg-greenGradient bg-no-repeat bg-cover h-screen flex flex-col items-center pt-6 lg:pt-12 min-h-[580px] min-w-[400px] max-h-screen">
            <div className="w-4/5 flex flex-col justify-center flex-wrap h-[90%] relative">
                <img
                    src={CCPLogo}
                    alt="logo"
                    className="absolute top-0 w-[60px] lg:w-[80px]"
                />
                <div className="flex gap-6 mt-12">
                    <div className="hidden lg:w-3/5 lg:block text-white mt-12">
                        <div className="mt-20">
                            <h1 className=" text-4xl xl:text-6xl">
                                Central Clearing Platform
                            </h1>
                            <p className="text-lg mt-6">
                                Swift Processing. Robust Settlements Engines.
                                Debt Recovery.
                            </p>
                        </div>
                    </div>
                    <div className="w-full lg:w-2/5 bg-white rounded p-4 xl:p-8 flex flex-col items-center z-10">
                        {children}
                    </div>
                </div>
            </div>
            <footer className="h-[60px] w-full fixed bottom-0 flex items-center justify-between px-16 text-xs text-white">
                <div>(c) 2021.Central Clearing Platform</div>
                <div>About | Terms of Use | Privacy Policy</div>
            </footer>
        </div>
    )
}

export default LandingLayout
