import { createContext, useState } from 'react'

export interface ICase {
    TicketClass: any
    PassengerType: any
    Rate: string
}

interface IScenario {
    Name: any
    FeeTypeId: any
    FeeType: any
    EbcAmountPerKG: string
    Cases: Array<ICase>
}

const defaultValue = {
    id: '',
    serviceType: '',
    contractInfo: {
        contractTitle: '',
        ContractDescription: '',
    },
    participants: {
        homeCountry1: '',
        organization1: '',
        partyRole1: '',
        homeCountry2: '',
        organization2: '',
        partyRole2: '',
    },
    tenor: {
        tenorStartDate: '',
        tenorEndDate: '',
    },
    scenarios: [
        {
            Name: '',
            FeeTypeId: '',
            FeeType: '',
            EbcAmountPerKG: '',
            Cases: [
                {
                    TicketClass: '',
                    PassengerType: '',
                    Rate: '',
                },
            ],
        },
    ],
    settlement: {
        settlementCycle: '',
        settlementStartDate: '',
        SettlementCycleFrequency: '',
        SettlementOffsetMinutes: '',
        settlementCurrency: '',
        settlementType: '',
        withdrawalBankAccount1: '',
        collectionBankAccount1: '',
        withdrawalBankAccount2: '',
        collectionBankAccount2: '',
    },
    formStep: 0,
}

interface IContractInfo {
    contractTitle: string
    ContractDescription: string
}

interface IParticipants {
    homeCountry1: any
    organization1: any
    partyRole1: any
    homeCountry2: any
    organization2: any
    partyRole2: any
}

interface ITenor {
    tenorStartDate: string
    tenorEndDate: string
}

interface ISettlement {
    settlementCycle: any
    SettlementCycleFrequency: string
    SettlementOffsetMinutes: string
    settlementStartDate: string
    settlementCurrency: any
    settlementType: any
    withdrawalBankAccount1: any
    collectionBankAccount1: any
    withdrawalBankAccount2: any
    collectionBankAccount2: any
}

interface IContext {
    id: string
    serviceType: string
    contractInfo: IContractInfo
    participants: IParticipants
    tenor: ITenor
    scenarios: Array<IScenario>
    settlement: ISettlement
    formStep: number
}

interface IData {
    allData: IContext
    setAllData: Function
    resetData: Function
}

export const ContractFormCtx = createContext<IData>({
    allData: defaultValue,
    setAllData: () => {},
    resetData: () => {},
})

export function ContractFormProvider({ children }: any) {
    const [allData, setAllData] = useState(defaultValue)
    const resetData = () => {
        setAllData(defaultValue)
    }
    return (
        <ContractFormCtx.Provider value={{ allData, setAllData, resetData }}>
            {children}
        </ContractFormCtx.Provider>
    )
}
