import React, { ReactElement, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GenericLayout from '../../components/GenericLayout'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { EmptyState } from '../../components/UIKit/EmptyState/EmptyState'
import { ErrorCard } from '../../components/UIKit/errorCard'
import { PageCount } from '../../components/UIKit/PageCount/PageCount'
import { PaginationComponent } from '../../components/UIKit/Pagination/PaginationComponent'
import { SearchInput } from '../../components/UIKit/Search/Search'
import { searchWorkFlow } from '../../utils/services/workflowApprovals'
import { WorkFlowDetails } from './record'

export function WorkFlow(): ReactElement {
    const [searchPhrase, setSearchPhrase] = useState('')
    // const [entriesCount, setEntriesCount] = useState(10)
    const navigate = useNavigate()
    // const { organizations, getOrganizations } = useOrganizations()
    const [filterType, setFilterType] = useState('Filter')
    const [filters, setFilters] = useState({
        from: 0,
        pageSize: 10,
        parameter: {
            name: searchPhrase,
            status: '',
        },
    })
    // const handleFilterType = (input: string) => {
    //     setFilterType(input)
    //     setFilters({
    //         ...filters,
    //         parameter: {
    //             ...filters.parameter,
    //             status: input,
    //         },
    //     })
    // }
    const handleSearch = (input: string) => {
        setSearchPhrase(input)
    }
    const handlePageChange = (page: { selected: number }) => {
        setFilters({
            ...filters,
            from: page?.selected + 1,
        })
    }
    const searchAction = () => {
        setFilters({
            ...filters,
            parameter: {
                ...filters.parameter,
                name: searchPhrase,
            },
        })
    }

    const {
        isLoading,
        isError,
        data: response,
        error,
        refetch,
    } = useQuery(
        [
            filters.from,
            filters.pageSize,
            filters.parameter.name,
            filters.parameter.status,
        ],
        () => searchWorkFlow(filters),
        {
            enabled: true,
            retry: false,
            onSuccess: (res) => {
                console.log('res', res)
            },
            onError: (err) => {
                // console.log('err', err)
            },
        }
    )
    useEffect(() => {
        refetch()
    }, [])
    return (
        <GenericLayout breadcrumbs={['Workflow']}>
            <div className="p-6 bg-[#F1F2F3]">
                <div className="flex justify-between items-baseline">
                    <p className="font-bold text-2xl text-[#002555]">
                        Workflow Instruments
                    </p>
                    <div
                        className="p-2 rounded text-sm text-white bg-[#219653] cursor-pointer"
                        onClick={() => navigate('/workflow/add')}
                    >
                        Add New Workflow
                    </div>
                </div>

                <div className="flex flex-col justify-items-start gap-4 xl:flex-row xl:justify-between pt-8">
                    <div className="flex flex-col justify-between md:flex-row gap-4">
                        <SearchInput
                            value={searchPhrase}
                            handleSearch={handleSearch}
                            onAction={searchAction}
                        />
                    </div>
                </div>
                {isLoading && (
                    <div className="pt-8">
                        <Skeleton className="px-5 py-3 my-2 h-32" count={5} />
                    </div>
                )}
                {isError && <ErrorCard />}
                {response?.data?.items?.length === 0 && (
                    <div className="mt-8">
                        <EmptyState />
                    </div>
                )}
                {response?.data?.items?.length > 0 && (
                    <div className="mt-8">
                        <WorkFlowDetails
                            cardList={response?.data.items}
                            refetch={refetch}
                        />
                        <PaginationComponent
                            pageSize={filters.pageSize}
                            totalCounts={response?.data.totalCount}
                            handlePageChange={handlePageChange}
                            currentPage={filters.from}
                        />
                    </div>
                )}
            </div>
        </GenericLayout>
    )
}
