import React, { ReactElement, ReactNode, useContext } from 'react'
import { UiContext } from '../../../../context/uiContext'
import { useLocation } from 'react-router-dom'
import { Dropdown } from '../Dropdown'

type IMenuProps<T> = {
    name: string
    icon?: string
    path?: string
    // key?:number
    menuAction?: Function
    currentTabHandler: Function
    dropDown?: boolean
    textColor?: string
    children?: React.ReactNode,
    configureSubMenu? :ReactNode
    disabled?:boolean
    active?:boolean
     
    options?: T[]
}
type DefaultOptionProps ={
    name:string
    url: string

} 
function MenuItem<T>({
    name,
    // key,
    icon,
    menuAction,
    path,
    currentTabHandler,
    configureSubMenu,
    dropDown,
    options,
    children,
    textColor,
    disabled,
    active
    
    
}: IMenuProps<T>): ReactElement {
    const location = useLocation()
    const formLoc = location.pathname.split('/')[1]
    const formPath = path?.split('/')[0]
    const { setShowDropDown, setToggleSidebar } = useContext(UiContext)

    const handleClick = () => {
        currentTabHandler(name)
        if (dropDown) {
            setShowDropDown(name)
        } else setToggleSidebar(false)
        // menuAction()
    }
    return (
        <li
            className={
                formLoc === formPath
                    ? 'flex items-center gap-4 py-3 px-5 font-bold text-xl text-[#002555] bg-[#e4f2ea] border border-l-4 border-l-cyan-900 hover:bg-gray-200 cursor-pointer'
                    : `flex items-center gap-4 py-3 ${!children? 'px-6 hover:bg-gray-200 ': ''} ${!dropDown? 'cursor-pointer': ''} ${active? 'bg-slate-100': ''}`
            }
            // key={key}
            onClick={()=> menuAction && menuAction()!}
        >
            {icon && (
                <div className="w-[18px] h-[18px] flex items-center">
                    <img src={icon} alt={name}  />
                </div>
            )}
            {!dropDown ? (
                <div className="flex font-Inter items-center justify-between w-full">
                    <span className={`text-sm ${textColor ? textColor : ''}`}>
                        {name}
                    </span>
                </div>
            ) : (
                <>
                    {children ? (
                        <Dropdown
                            label={name}
                            options={options as any}
                            textColor={textColor}
                            children={children}
                            configureSubMenu={configureSubMenu}
                            onClick={handleClick}
                            disabled={disabled}
                        />
                    ) : (
                        <Dropdown
                            label={name}
                            options={options as unknown as DefaultOptionProps[]} 
                            textColor={textColor} 
                            onClick={handleClick}     
                            disabled={disabled}                    
                        />
                    )}
                </>
            )}
        </li>
    )
}

export default MenuItem
