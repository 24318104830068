import { Suspense, lazy } from 'react'
const ContractDetail = lazy(
    () => import('../../../pages/Contract/ContractDetail/AOG/ContractDetail')
)
const ContractDetailGHS = lazy(
    () => import('../../../pages/Contract/ContractDetail/GHS/ContractDetailGHS')
)
const ContractGroup = lazy(
    () => import('../../../pages/Contract/ContractGroup')
)
const ContractListing = lazy(
    () => import('../../../pages/Contract/ContractListing/ContractListing')
)

const ContractService = lazy(
    () => import('../../../pages/Contract/ContractService/ContractService')
)
import { getUserPermissions } from '../../../utils/localStorage'
import { LandingBg } from '../../LandingBg/LandingBg'

import { IRoute } from './PermissionRoutes'

const permissions = getUserPermissions()

export const contractServiceRoutes: IRoute[] = [
    {
        path: 'contract',
        comp: <ContractGroup />,
        isAllowed: permissions.has('contract'),
    },
    {
        path: 'contract/services',
        comp: <ContractService />,
        isAllowed: permissions.has('contract'),
    },
    {
        path: 'contract/listing/:id',
        comp: <ContractListing />,
        isAllowed: permissions.has('contract'),
    },
    {
        path: 'contract/AOG/detail',
        comp: <ContractDetail />,
        isAllowed: permissions.has('contract'),
    },
    {
        path: 'contract/GHS/detail',
        comp: <ContractDetailGHS />,
        isAllowed: permissions.has('contract'),
    },
]
