import { devInstance } from '../../axios/axiosInstance'

export const searchOrganization = async (payload: any) => {
    const data = await devInstance.post('/Organization/search', payload)
    return data
}

export const addOrganizationContact = async (payload: any) => {
    const response = await devInstance.post('/Organization/create', payload)
    return response
}

export const fetchOrganizations = async (payload: any) => {
    const response = await devInstance.post('/Organization/search', payload)
    return response
}

export const updateOrgStatus = async (payload: any) => {
    const response = await devInstance.post(
        `/Organization/updatestatus?organizationId=${payload.orgId}&status=${payload.newStatus}`,
        payload
    )
    return response
}

export const fetchAllOrganizations = async (payload: any) => {
    const response = await devInstance.post('/Organization/search', payload)
    return response
}

export const getBankAccountByOrganizationId = async (orgId: any) => {
    const response = await devInstance.get(
        `/Organization/GetBankAccountByOrganizationId?organizationId=${orgId}`
    )
    return response
}

export const getLocationByOrganizationId = async (payload: any) => {
    const response = await devInstance.get(
        `/Organization/GetLocationByOrganizationId?organizationId=${payload.organizationId}`,
        payload
    )
    return response
}

export const getOrganizationById = async (payload: any) => {
    const response = await devInstance.get(
        `Organization/getById?id=${payload.organizationId}`,
        payload
    )
    return response
}

export const getAllOrganizations = async () => {
    const response = await devInstance.get('/Organization/getall')
    return response
}
