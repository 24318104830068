import React, { FC } from 'react'

const AdminContent: FC = ({ children }) => {
    return (
        <main className="absolute w-full left-0 bg-[#F1F2F3]  lg:left-[220px] lg:w-[calc(100%-220px)]">
            {children}
        </main>
    )
}

export default AdminContent
