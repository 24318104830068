import { devInstance } from '../axios/axiosInstance'

export const searchContract = async (payload: any) => {
    const data = await devInstance.post('/Contract/SearchWithDetails', payload)
    return data
}

export const fetchContractGroups = async (payload: any) => {
    const data = await devInstance.get('/Contract/GetContractGroups', payload)
    return data
}

export const createContract = async (payload: any) => {
    const data = await devInstance.post('/ContractService/Create', payload)
    return data
}

export const getContractById = async (payload: any) => {
    const data = await devInstance.post(
        `/ContractService/GetById?id=${payload.id}`,
        payload
    )
    return data
}

export const getAllContractService = async (payload: any) => {
    const data = await devInstance.get(`ContractService/GetAll`, payload)
    return data
}

export const getContractServices = async () => {
    const data = await devInstance.get(`ContractService/OrganizationServices`)
    return data
}

export const getContractServiceByOrganization = async () => {
    const data = await devInstance.get(
        `Contract/GetContractServiceByOrganization`
    )
    return data
}
